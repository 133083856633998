
import { computed, defineComponent, onBeforeUnmount } from 'vue'
import { getDataCredential } from '@/helpers/util/webApiUtil'
import { useRoute, useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'

const BASE_URL = process.env.VUE_APP_DMS_API_BASE_URL

export default defineComponent({
  name: 'DmsMain',
  setup () {
    const route = useRoute()
    const router = useRouter()
    const path = computed(() => route.path)

    // 日本語固定化
    const i18n = useI18n()
    const defaultLocale = i18n.locale.value
    i18n.locale.value = 'ja'

    const sessionCheck = async () => {
      if (path.value.startsWith('/dms/outer')) {
        const url = `${BASE_URL}/outer/login/success/`
        getDataCredential(url)
      }
    }
    sessionCheck()
    onBeforeUnmount(() => {
      // 本来の選択言語に戻す
      i18n.locale.value = defaultLocale
    })
  },
})
